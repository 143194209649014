import {enviarMensajeAPI} from "@/model/contacto/contacto.repository";

const enviarMensaje = (obj,token) => {
    return new Promise((resolve, reject) => {
        enviarMensajeAPI(obj,token).then((response) => {
            if (response.data.status) {
                resolve(response.data.obj);
            } else {
                reject({ response: response, mensaje: `No se pudo enviar el mensaje, favor intentarlo nuevamente.` })
            }
        }).catch((error) => {
            reject(error);
        })
    })
}

export default enviarMensaje;