import axios from "axios";
import qs from "qs";

export const enviarMensajeAPI = (obj,token) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/clientes?action=enviar-correo-contacto",
            qs.stringify({obj: JSON.stringify(obj),token: token})
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}